<style lang="scss">
@import '~bootstrap';

html,
body {
  &::-webkit-scrollbar {
    width: 18px;
  }
  &::-webkit-scrollbar-track {
    background: #eee;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 20px;
    border: 4px solid #eee;
  }
}

#landingpage {
  header {
    //border:1px solid red;
    padding: 30px 0;
    .logo {
      max-width: 130px;
      @media (max-width: $width-lg) {
        margin: 0 auto;
      }
      img {
      }
    }
    .txt-box {
      text-align: right;
      @media (max-width: $width-lg) {
        text-align: center;
      }
      h1 {
        font-family: $KleineTitel-Bold;
        @media (max-width: $width-lg) {
          margin-top: 20px;
          padding: 0 20px;
          text-align: center;
        }
      }
    }
  }
  .top-header {
    width: 100%;
    height: 700px;
    //border:1px solid blue;
    max-width: 1400px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
    position: relative;
    z-index: 5;
    @media (max-width: $width-lg) {
      width: 96%;
      height: 500px;
    }
    .top-header-bg {
      left: 0px;
      top: 4%;
      transform: scale(1.02);
      margin: 0px;
      position: absolute;
      width: 100%;
      height: 98%;
      background: $blue;
      border-radius: 40px;
      display: none;
    }
    .header-image-slideshow {
      overflow: hidden;
      position: absolute;
      width: 100%;
      height: 100%;
      overflow: hidden;
      border-radius: 30px;
      .bg-gradient {
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        //background-color: rgba($color: $blue-light, $alpha: 0.8);
        background: rgb(2, 0, 36);
        background: linear-gradient(180deg, rgba(2, 0, 36, 0) 0%, rgba(92, 109, 198, 1) 100%);
      }
      .txt-box {
        position: absolute;
        width: 100%;
        bottom: 80px;
        left: 0;
        text-align: center;
        z-index: 100;
        @media (max-width: $width-lg) {
          bottom: 40px;
          padding: 0 20px;
        }
        h2 {
          border-left: 0;
          color: #fff;
        }
      }
      .slideshow-image {
        border: 2px solid blue;
        position: absolute;

        width: 100%;
        height: 100%;
        //background: no-repeat 50% 50%;
        background-repeat: no-repeat;
        background-size: cover;
        animation: backGroundAni 12s infinite;

        transform: scale(1.2);
        @media (max-width: $width-lg) {
          animation: none;
        }
      }

      @keyframes backGroundAni {
        0% {
          background-size: 100%;
        }
        50% {
          background-size: 105%;
        }
        100% {
          background-size: 100%;
        }
      }
    }
  }
  .bg-blue-light {
    position: relative;
    top: -70px;
    z-index: 0;
  }
  .kontakt-formular {
    padding-top: 100px;

    h2 {
      border-left: 4px solid $red;
      padding: 5px 15px 7px 15px;
      margin: 20px 0;
      strong {
        text-transform: none;
      }
    }

    .form-group {
      //border:1px solid red;
      margin-bottom: 15px;
      .label-input {
        margin-bottom: 5px;
        display: block;
        font-size: 14px;
        letter-spacing: 1px;
      }
      input,
      textarea {
        display: block;
        width: 100%;
        border-radius: 0 !important;
        border: 1px solid #ccc;
        padding: 0.7em 0.8em;
        outline: none;
        font-size: 100%;
        letter-spacing: 1px;
        option {
          letter-spacing: 1px;
        }
        &:focus {
          border-radius: 0 !important;
          border: 1px solid $blue;
          box-shadow: none;
        }
      }

      textarea {
        min-height: 180px;
      }
    }

    .form-group-v2 {
      border: 1px solid blue;
    }

    .form-select {
      border: 1px solid #ccc;
      width: 100%;
      padding: 0.7em 0.8em;
      padding-right: 20px;
      background-position-x: 25px;
      &:focus,
      &.active,
      &:focus-visible {
        outline: none;
        border-radius: 0;
      }
    }

    .btn-formular {
      border: 0;
      display: block;
      width: 60%;
      margin: 0 auto;
      padding: 20px 35px;
      line-height: 1;
      background: $blue-darker;
      color: #fff;
      letter-spacing: 1px;
      &:active,
      &:focus {
        border: 0;
        outline: none;
      }
      @media (max-width: $width-lg) {
        display: block;
        width: 100%;
      }
      &:hover {
        background: $blue-light;
      }
    }
    .success-msg {
      font-family: $KleineSans-Bold;
      font-size: 140%;
      text-align: center;
      padding: 20px;
    }
    .error-box {
      margin-top: 10px;
      ul {
        padding: 0;
        margin: 0;
        list-style-type: none;
        li {
          text-align: center;
          margin-top: 3px;
          color: $red;
        }
      }
    }
  }
  .info-grafik {
    padding: 50px 0;
    .txt-box {
      padding: 0px 0px;
      //border:1px solid red;
      text-align: center;
      margin: 15px 0;

      @media (max-width: $width-md) {
        //border-bottom: 2px solid $red;
        border-left: 0;
        text-align: center !important;
      }
      .img-icon {
        margin-top: 30px;
        margin-bottom: 30px;
        display: inline-block;
        padding: 0 10px;
        height: 90px;
        img {
          position: relative;
          top: 50%;
          transform: translateY(-50%);
        }
      }
      .img-std {
        img {
          width: 100px;
        }
      }
      .img-karte {
        img {
          width: 160px;
        }
      }
      .img-uhr {
        img {
          width: 70px;
        }
      }
      .nr {
        font-size: 240%;
        color: $blue-darker;
        line-height: 1;
        letter-spacing: 4px;
        font-family: $KleineTitelCondensed-Bold;
      }
      p {
        margin: 15px 0;
        @media (max-width: $width-md) {
          padding: 10px 10px 30px 10px;
        }
      }
    }
    .border-bottom-none {
      @media (max-width: $width-md) {
        border-bottom: 0;
      }
    }
    .border-line {
      width: 100%;
      height: 1px;
      background: $red;
      margin: 10px 0;
      display: none;
      @media (max-width: $width-md) {
        display: none;
      }
    }
    .border-right-line {
      //border-right: 1px solid $red;
      @media (max-width: $width-md) {
        border-right: 0;
      }
    }
    .border-right-line-lg {
      @media (max-width: $width-lg) {
        border-right: 0;
      }
    }
  }
  footer {
    .kontakt-box {
      text-align: center;
      border-right: 5px solid $blue-light-v2;
      padding: 20px 0px;
      @media (max-width: $width-md) {
        border-right: 0;
        &:after {
          content: '';
          display: block;
          width: 100px;
          height: 5px;
          background: $blue-light-v2;
          margin: 40px auto 0 auto;
        }
      }
      &:last-child {
        border-right: 0;
        @media (max-width: $width-md) {
          &:after {
            display: none;
          }
        }
      }
      h3 {
        margin-bottom: 20px;
      }
      span {
        @media (max-width: 1350px) and (min-width: 1200px) {
          display: block;
        }
      }
      a {
        &:hover {
          color: $blue !important;
        }
      }
    }
  }
}
</style>

<template>
  <div id="landingpage">
    <div class="container">
      <header>
        <div class="row">
          <div class="col-lg-3 align-self-center">
            <div class="logo">
              <img src="@/assets/img/generel/klz-logo.svg" alt="Kleine Zeitung" class="img-fluid" />
            </div>
          </div>
          <div class="col-lg-9 align-self-center">
            <div class="txt-box">
              <h1>Wir verbinden Marken mit Menschen.</h1>
              <p>Täglich. National. Regional.</p>
            </div>
          </div>
        </div>
      </header>
    </div>
    <div class="top-header ">
      <div class="top-header-bg"></div>
      <div class="header-image-slideshow shadow-lg">
        <div class="slideshow-image" :style="{ 'background-image': `url(${require('@/assets/img/img-content/landingpage/zeitung.jpg')})` }" style="background-position:center center;"></div>
        <div class="bg-gradient"></div>
        <div class="txt-box">
          <h2>Kommunikationslösungen, die Sie begeistern werden.</h2>
        </div>
      </div>
    </div>
    <div class="bg-blue-light">
      <div class="container kontakt-formular">
        <div class="row justify-content-center margin-t-m">
          <div class="col-lg-5 align-self-center">
            <h2>Treten Sie mit uns in Kontakt</h2>
          </div>
          <div class="col-lg-5 align-self-center">
            <div class="text-lg-right">
              <CollapsibleButton link="formular-content" class="content-link-mehrinfo"><span class="material-icons">arrow_right_alt</span>Zum Formular</CollapsibleButton>
            </div>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-lg-10 margin-b-l margin-t-s">
            <!-- -->

            <Collapsible link="formular-content">
              <div class="margin-b-l margin-t-s" v-if="!sent">
                <!-- -->
                <div class="row">
                  <div class="col-lg-4">
                    <div class="form-group">
                      <div class="label-input">Vorname *</div>
                      <input type="text" required v-model="firstname" />
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="form-group">
                      <div class="label-input">Nachname *</div>
                      <input type="text" required v-model="lastname" />
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="form-group">
                      <div class="label-input">Unternehmen *</div>
                      <input type="text" required v-model="company" />
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group">
                      <div class="label-input">E-Mail-Adresse *</div>
                      <input type="email" required v-model="email" />
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group">
                      <div class="label-input">Telefonnummer *</div>
                      <input type="text" required v-model="telephone" />
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="form-group">
                      <div class="label-input">Ich interessiere mich besonders für</div>
                      <select class="form-select" v-model="interest">
                        <option :value="index" :key="index" v-for="(interest, index) of interests">{{ interest }}</option>
                      </select>
                    </div>
                  </div>

                  <div class="col-lg-12" v-if="interest == 4">
                    <div class="form-group">
                      <div class="label-input">Branchenlösung</div>
                      <select class="form-select" v-model="branchsolution">
                        <option :value="index" :key="index" v-for="(solution, index) of solutions">{{ solution }}</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-lg-12" v-if="branchsolution == 6">
                    <div class="form-group">
                      <div class="label-input">Branchenlösung // Sonstiges</div>
                      <input type="text" v-model="customsolution" />
                    </div>
                  </div>

                  <div class="col-lg-12">
                    <div class="form-group">
                      <div class="label-input">Möchten Sie uns noch etwas mitteilen?</div>
                      <textarea v-model="text"></textarea>
                    </div>
                  </div>

                  <div class="col-lg-12">
                    <a href="https://www.kleinezeitung.at/service/unternehmen/impressum/5104679/Datenschutz_Datenschutzinformation-Werbemarkt" target="_blank" class="text-link-std">Wir möchten Sie auf unsere Datenschutzerklärung aufmerksam machen. <strong>Hier klicken</strong></a>
                  </div>

                  <div class="col-lg-12 col-md-10 margin-t-s">
                    <button class="btn-formular" @click="send">Anfrage absenden</button>
                  </div>
                </div>

                <div class="row justify-content-center error-box" v-if="errors.length > 0">
                  <div class="col-lg-12 col-md-10 text-center">
                    <ul>
                      <li :key="error" v-for="error of errors">{{ error }}</li>
                    </ul>
                  </div>
                </div>
              </div>

              <div class="col-lg-10 margin-b-l margin-t-s" v-if="sent">
                <div class="success-msg">
                  Vielen Dank für Ihre Anfrage. Unser Service-Team kümmert sich umgehend darum. In Kürze wird sich eine Mitarbeiterin bzw. ein Mitarbeiter bei Ihnen melden.
                </div>
              </div>
            </Collapsible>
          </div>
        </div>
      </div>
    </div>
    <!-- -->
    <div class="info-grafik margin-t-s">
      <div class="container">
        <div class="row justify-content-center ">
          <div class="col-lg-4 col-md-6 border-right-line">
            <div class="txt-box">
              <div class="img-icon img-std"><img src="@/assets/img/generel/Leute-Icon.svg" alt="Leute" /></div>
              <div class="nr ">752.000</div>
              <p>Leserinnen und Leser hat die Kleine Zeitung in der Steiermark und in Kärnten und damit die höchste Reichweite aller Tageszeitungen im Süden Österreichs.</p>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 border-right-line border-right-line-lg ">
            <div class="txt-box ">
              <div class="img-icon img-std"><img src="@/assets/img/generel/Devices-Icon.svg" alt="Leute" /></div>
              <div class="nr">1,9</div>
              <p>Millionen Userinnen und User erreicht die Kleine Zeitung zusätzlich auf ihren digitalen Kanälen.</p>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="txt-box">
              <div class="img-icon img-uhr"><img src="@/assets/img/generel/Uhr-Icon.svg" alt="Leute" /></div>
              <div class="nr">24/7</div>
              <p>Die Kleine Zeitung erscheint Montag bis Sonntag. 7 Tage die Woche – das ganze Jahr. Mit der Website, der App und den sozialen Kanälen erreicht sie ihre Leser auf allen Kanälen. Zu jeder Zeit.</p>
            </div>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-lg-8 border-line"></div>
        </div>
        <div class="row justify-content-center">
          <div class="col-lg-4 col-md-6 border-right-line">
            <div class="txt-box">
              <div class="img-icon img-karte"><img src="@/assets/img/generel/Landkarte-Icon.svg" alt="Leute" /></div>
              <div class="nr">18</div>
              <p>Regionalausgaben (10 in der Steiermark und 8 in Kärnten) berichten täglich aus dem unmittelbaren Lebensumfeld der Leserinnen und Leser.</p>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="txt-box border-bottom-none">
              <div class="img-icon img-std"><img src="@/assets/img/generel/Treue-Icon.svg" alt="Leute" /></div>
              <div class="nr">97,5%</div>
              <p>aller Leserinnen und Leser halten ihrer “Kleinen” die Treue und beziehen die Kleine Zeitung im Abo – print und digital.</p>
            </div>
          </div>
        </div>
        <div class="row justify-content-center align-self-center">
          <div class="col-sm-6 d-flex align-self-center justify-content-center">
            <div class="btn-std btn-bg-blue-dark margin-t-m">
              <a href="../../pdf/allgemeines/KLZ-Fact-Sheet_Tarif_2021-v2.pdf" target="_blank">Weitere Informationen</a>
            </div>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-10 text-center padding-t-s">
            <div class="quelle-txt ">
              MA 2020/2021, Erhebungszeitraum: Juli 2020 bis Juni 2021,; ÖAK 1. Halbjahr 2021, abonnierte Auflage (inklusive E-Paper). Wochenschnitt Mo.-Sa.; ÖWA plus 2019
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer class="margin-tb-m">
      <div class="container">
        <div class="row justify-content-center align-self-center bg-blue-light highlight-box ">
          <div class="col-sm-6 d-flex align-self-center">
            <p>Entdecken Sie unser Produktportfolio</p>
          </div>
          <div class="col-sm-6 d-flex align-self-center justify-content-end">
            <div class="btn-std btn-bg-blue-dark">
              <router-link to="/" target="_blank">tarif.kleinezeitung.at</router-link>
            </div>
          </div>
        </div>
        <div class="row margin-tb-m">
          <div class="col-lg-4 col-md-4 kontakt-box">
            <h3>Werbemarkt<br />Steiermark</h3>
            <p>
              Gadollaplatz 1<br />
              8010 Graz
            </p>
            <p><span>Tel.: </span><a href="tel:0316/875 33 02">0316/875 33 02</a></p>
          </div>
          <div class="col-lg-4 col-md-4 kontakt-box">
            <h3>Werbemarkt<br />Kärnten</h3>
            <p>
              Hasnerstraße 2<br />
              9020 Klagenfurt
            </p>
            <p><span>Tel.:</span> <a href="tel:0463/58 00 286">0463/58 00 286</a></p>
          </div>
          <div class="col-lg-4 col-md-4 kontakt-box">
            <h3>Werbemarkt<br />National</h3>
            <p>
              Lobkowitzplatz 1<br />
              1010 Wien
            </p>
            <p><span>Tel.:</span> <a href="tel:01/512 16 18">01/512 16 18</a></p>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-lg-4 text-center">
            <div class="btn-std btn-bg-blue-dark">
              <a href="mailto:meinewerbung@kleinezeitung.at">meinewerbung@kleinezeitung.at</a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import { useStore } from 'vuex';
import CollapsibleButton from '@/components/util/CollapsibleButton.vue';
import Collapsible from '@/components/util/Collapsible.vue';

export default defineComponent({
  components: {
    CollapsibleButton,
    Collapsible,
  },
  setup() {
    const errors = ref<Array<string>>([]);
    const store = useStore();
    const firstname = ref('');
    const lastname = ref('');
    const company = ref('');
    const email = ref('');
    const telephone = ref('');
    const interest = ref(0);
    const branchsolution = ref(0);
    const text = ref('');
    const customsolution = ref('');

    const sending = ref(false);
    const sent = ref(false);

    const interests = ['Bitte wählen ...', 'Werbung in der Tageszeitung', 'Online-Werbung', 'Themenschwerpunkte', 'Branchenlösungen'];
    const solutions = ['Bitte wählen ...', 'Bildung & Karriere', 'Bauen & Wohnen', 'Moblität', 'Reise', 'Gesundheit', 'Sonstiges'];

    const send = () => {
      if (sending.value) return false;

      errors.value = [];

      if (firstname.value == '') {
        errors.value.push('Bitte geben Sie einen Vornamen an');
      }

      if (lastname.value == '') {
        errors.value.push('Bitte geben Sie einen Nachnamen an');
      }

      if (company.value == '') {
        errors.value.push('Bitte geben Sie eine Firma an');
      }

      if (email.value == '') {
        errors.value.push('Bitte geben Sie eine E-Mail-Adresse an');
      }

      if (telephone.value == '') {
        errors.value.push('Bitte geben Sie eine Telefonnummer an');
      }

      if (interest.value == 0) {
        errors.value.push('Bitte geben Sie ein Interesse an');
      }

      if (branchsolution.value == 0 && interest.value == 4) {
        errors.value.push('Bitte geben Sie eine Branchenlösung an');
      }

      if (branchsolution.value == 6 && interest.value == 4 && customsolution.value == '') {
        errors.value.push('Bitte geben Sie eine Branchenlösung an');
      }

      if (errors.value.length <= 0) {
        sending.value = true;
        store
          .dispatch('sendEmailLanding', {
            firstname: firstname.value,
            lastname: lastname.value,
            company: company.value,
            email: email.value,
            telephone: telephone.value,
            interest: interests[interest.value],
            branchsolution: branchsolution.value < 6 ? solutions[branchsolution.value] : customsolution.value,
            text: text.value,
          })
          .then((res: any) => {
            sending.value = false;
            sent.value = true;
          });
      }
    };

    return {
      firstname,
      lastname,
      company,
      email,
      telephone,
      interest,
      branchsolution,
      text,
      interests,
      solutions,
      customsolution,
      errors,
      send,
      sent,
    };
  },
});
</script>
